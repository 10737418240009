
export class HeaderGrid {
    constructor(name, value, options) {
        this.name = name
        this.value = value
        this.type = 'normal'
        this.img = null
        this.function = null;
        this.formula = null;
        this.editable = false;
        if(options) {
            this.type = options.type ?? this.type;
            this.editable = options.editable;
            this.function = options.function;
            this.formula = options.formula;
            this.component = options.component ? options.component : null;
            if (this.component) {
                this.component_options = options.component_options ? options.component_options : {};
            }
            if (options.formula) 
            {
                this.expression = options.expression;
                if (options.formula == "boolean_express"){
                    this.true_res = options.true_res;
                    this.false_res = options.false_res;
                }
            }
            if (options.data) 
            {
                this.data = options.data;
                this.data_key = options.data_key;
                this.data_search_key = options.data_search_key;
                this.data_value = options.data_value;
            }
            this.img = options.img;
            if (options.img) {
                this.img_field = options.img_field;
                this.img_ext = options.img_ext;
            }
        }
    }
}

export class CatalogInput { 
    constructor(label, model, type, options) { 
        this.label = label;
        this.model = model;
        this.type = type ? type : 'text';
        if (options) {
            this.cols = options.cols ? options.cols : null;
            this.options = options.options ? options.options : null;
            this.options_label = options.options_label ? options.options_label : null;
            this.options_value = options.options_value ? options.options_value : null;
        }
    }
}

export class ValidateForm {
    constructor(rules) { 
        this.valid = false;
        this.validations = {};
        this.message = "\n \n \n";
        if (rules) {
           rules.forEach(rule => {
            this.validations[rule.name] = null;
           });
        }
    }
    
    clear() {
        Object.keys(this.validations).forEach(key => {
            this.validations[key] = null;
        })
    }
}

export class Rule {
    constructor(rule) {
        this.name = rule.name;
        this.type = rule.type ? rule.type : "required";
        this.value = rule.value ? rule.value : "text";
        this.active = true;
        this.description = rule.description ? rule.description : rule.name;
        if (this.value == "number") {
            this.min = rule.min;
            this.max = rule.max; 
        }
    }
}

export function fillObject(fill, filler) {
    Object.keys(filler).forEach(prop => {
        fill[prop] = filler[prop];
    })
    return fill;
} 

export class Toast {
    constructor(args) {
        this.severity = args.severity ? args.severity : "success";
        this.summary = args.summary ? args.summary : "Mensaje";
        this.detail = args.detail ? args.detail : "Mensaje random, no se encontro mensaje";
        this.life = args.life ? args.life : 3000;
    }
}

export class ErrorResponse {
    constructor(error) {
        this.message = error;
        if (error.response) {
            this.message = error.response;
            if (error.response.data) {
                this.message = error.response.data;
                if (error.response.message) {
                    this.message = error.response.data.message;
                }
            }
        }
    }
}

export class ErrorToast extends Toast {
    constructor(error, options) {
        var message = error;
        if (error.response) {
            message = error.response;
            if (error.response.data) {
                message = error.response.data;
                if (error.response.message) {
                    message = error.response.data.message;
                }
            }
        }

        let detail = options ? (options.detail ?? message) : message;
        let life = options ? (options.life ?? 3000) : 3000;
        let summary = options ? (options.summary ?? "Error") : "Error";
        let severity = options ? (options.severity ?? "error") : "error";

        super({
            severity: severity,
            summary: summary,
            detail: detail,
            life: life
        })
        console.log("ERROR_LOG: " + message);
    }
}

export class WarnToast extends Toast {
    constructor(message) {
        super({
            severity: "warn",
            summary: "Alerta",
            detail: message
        })
    }
}

function textValidations(entity, rule) {
    //* Siempre es valido antes de la formulacion
    let res = true;
    if (rule.type == "required") 
        return entity[rule.name] ? (entity[rule.name] != "") : false;
    else if (rule.type == "rfc") 
        return entity[rule.name] ? (entity[rule.name].length > 11 && entity[rule.name].length < 14) : false;
    else if (rule.type == "email") 
        /*eslint-disable */
        return entity[rule.name] ? (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(entity[rule.name])) : false;
        /*eslint-enable */
    return res;
}

function numberValidations(entity, rule) {
    //* Siempre es valido antes de la formulacion
    let res = true;
    switch (rule.type) {
        case "required":
            res = entity[rule.name] ? (entity[rule.name] != "") : false; break; 
        case "positive":
            res = entity[rule.name] ? (parseInt(entity[rule.name]) >= 0) : false; break;
        case "negative":
            res = entity[rule.name] ? (parseInt(entity[rule.name]) <= 0) : false; break;
        case "min":
            res = entity[rule.name] ? (parseInt(entity[rule.name]) > rule.min) : false; break;
        case "min-equal":
            res = entity[rule.name] ? (parseInt(entity[rule.name]) >= rule.min) : false; break;
        case "max":
            res = entity[rule.name] ? (parseInt(entity[rule.name]) < rule.max) : false; break;
        case "max-equal":
            res = entity[rule.name] ? (parseInt(entity[rule.name]) <= rule.max) : false; break;
        case "between":
            res = entity[rule.name] ? (parseInt(entity[rule.name]) >= rule.min && parseInt(entity[rule.name]) <= rule.max) : false; break;
        default:
            break;
    }
    return res;
}

export async function satCatalogo(ver) {
    let catalogo = null;
    switch (ver) {
        case 1: catalogo = '/data/sat/c_ClaveProdServ.json'; break;
        case 2: catalogo = '/data/sat/c_RegimenFiscal.json'; break;
        case 3: catalogo = '/data/sat/c_MetodoPago.json'; break;
        case 4: catalogo = '/data/sat/c_Estado.json'; break;
        case 5: catalogo = '/data/sat/c_Exportacion.json'; break;
        case 6: catalogo = '/data/sat/c_FormaPago.json'; break;
        case 7: catalogo = '/data/sat/c_Localidad.json'; break;
        case 8: catalogo = '/data/sat/c_Meses.json'; break;
        case 9: catalogo = '/data/sat/c_Moneda.json'; break;
        case 10: catalogo = '/data/sat/c_Municipio.json'; break;
        case 11: catalogo = '/data/sat/c_Pais.json'; break;
        case 12: catalogo = '/data/sat/c_Periodicidad.json'; break;
        case 13: catalogo = '/data/sat/c_TipoComprobante.json'; break;
        case 14: catalogo = '/data/sat/c_TipoRelacion.json'; break;
        case 15: catalogo = '/data/sat/c_UsoCFDI.json'; break;
        case 16: catalogo = '/data/sat/c_ClaveUnidad.json'; break;
        case 17: catalogo = '/data/sat/c_TipoRelacionCancelacion.json'; break;
        case 18: catalogo = '/data/sat/c_Periodicidad.json'; break;
        case 19: catalogo = '/data/sat/c_Meses.json'; break;
        case 20: catalogo = '/data/sat/c_SubTipoRem.json'; break;
        case 21: catalogo = '/data/sat/c_FiguraTransporte.json'; break;
        case 22: catalogo = '/data/sat/c_TipoPermiso.json'; break;
        case 23: catalogo = '/data/sat/c_ConfigAutotransporte.json'; break;
        case 24: catalogo = '/data/sat/c_CveTransporte.json'; break;
        case 25: catalogo = '/data/sat/c_ParteTransporte.json'; break;
        case 26: catalogo = '/data/sat/c_ClaveProdServCP.json'; break;
        case 27: catalogo = '/data/sat/c_UnidadAduana.json'; break;
        case 28: catalogo = '/data/sat/c_Banco.json'; break;
        default: catalogo = null; break;
    }
    let res = await fetch(catalogo);
    let json = await res.json();
    return json;
} 

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export function validate (entity, rules) {
    if (!entity) 
        throw "No se han especificado el registro a validar, no se puede completar la validacion"
    if (!rules) 
        throw "No se han especificado las reglas, no se puede completar la validacion"
    
    //* Nuevo cambio, el objeto validate siempre ira de acuerdo a rules
    let response = new ValidateForm(rules);
    response.valid = true;
    //* Nuevo cambio, podemos activar y desactivar reglas dependiendo de nuestro uso
    rules.filter(x => x.active).forEach(rule => {
        response.validations[rule.name] = 
              rule.value == "text"  ? textValidations(entity, rule) 
            : rule.value == "number" ? numberValidations(entity, rule)
            : null;
    });

    rules.filter(x => !x.active).forEach(rule => {
        response.validations[rule.name] = true;
    });
    //* Si invalid es undefined, esta valido
    Object.keys(response.validations).forEach(props => {
        response.valid =  response.valid ? response.validations[props] : false;
        if (response.validations[props]) {
            response.message += rules.find(x => x.name == props).description + ", \n \n \n";
        }
    })
    response.message.trimEnd(',');
    return response;
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function randomColor() {
    return "#" + Math.floor(Math.random()*16777215).toString(16);
}

import axios from 'axios';

export async function countries() {
    var response = await axios.get('general/country');
    return response.data;
}

export const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(module => {
        if (module && module.default) {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });

            module.default.saveAs(data, fileName + EXCEL_EXTENSION);
        }
    });
}

export async function movementTypes() {
    let res = await fetch('/data/movement_types.json');
    let json = await res.json();
    return  {
        entradas: json.data.filter(x => x.in),
        salidas: json.data.filter(x => !x.in),
    }
}