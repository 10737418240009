<template>
  <Dialog
    :closeOnEscape="false"
    :closable="false"
    :modal="true"
    :style="{ width: '50%' }"

    v-model:visible="localValue"
  >
    <template #header>
      <h3>Cargando</h3>
    </template>
    <div class="card">
      <ProgressBar mode="indeterminate" />
    </div>
  </Dialog>
</template>

<script>
import { sleep } from '../../utilities/General';
export default {
  props: {
    modelValue: {
      required: true,
    },
    minLife: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      life: 0,
      localValue: this.modelValue,
    };
  },
  watch: {
    localValue(newValue) {
      this.$emit('update:modelValue', newValue);
    },
    async modelValue(newValue, oldValue) {
      if (newValue === true) {
        //* Aqui enciende
        let vueData = this;
        this.lifeInterval = setInterval(function () {
          vueData.life += 1000;
        }, 1000);
      }
      if (newValue === false && oldValue === true) {
        //* Minimo de espera para que no "flashee" el componente
        if (this.life < this.minLife) {
            await sleep(this.minLife - this.life);
        }
        //* Apagamos el intervalo
        clearInterval(this.lifeInterval);
        //* Reiniciamos *life* por que ya esta a punto de cerrarse
        this.life = 0;
      }
      this.localValue = newValue;
    },
  },
};
</script>

<style></style>
